import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { EmploymentType, BasicDetailConfig, validationForFields, EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../../config/constant';
import InputField from '../element/Input';
import { JoiningDate, Anniversary, DOBDate, Calender3 } from './DatePicker'
import MasterService from '../../services/MasterService';
import ToggleSwitch from '../element/ToggleSwitch';
import Loader from '../common/loader';
var md5 = require('md5');
const { ValueContainer, Placeholder } = components;




const BasicDetails = (props) => {
    const { stateList, user_detail, ConfigList } = props
    // let prev_and_conf_errors = props
    const [bankList, setBankList] = useState([])
    const [ReferralName, setReferralName] = useState([])
    const [basic_details, setBasicDetail] = useState({ password: "", confirm_password: "", incentive_percentage: 0 });
    const [is_existing, setIsExisting] = useState(false);
    const [errors, SetErrors] = useState(props.location_state && props.location_state.tempErrors ? props.location_state.tempErrors : {});
    const [cityList, setCityList] = useState([])
    const [loader, setLoader] = useState(false)
    const [current_password, setCurrentPassword] = useState("")
    const [change_password, setChangePassword] = useState(false)
    const [localityList, setLocalityList] = useState([])
    const [existing_errors, SetExistingErrors] = useState({});
    const [password_type, setPasswordType] = useState("password")
    const [confirm_password_type, setConfirmPasswordType] = useState("password")
    const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const [account_length, setAccountLength] = useState(0)
    const [show_password_value, setShowPasswordValue] = useState(true)
    const [show_confirm_password_value, setShowConfirmPasswordValue] = useState(true)
    // const [enable_new_password, setEnableNewPassword] = useState(false)

    const historyLead = useNavigate();

    useEffect(() => {
        if (ConfigList.length === 0) {
            props.handleReset()
            if (is_edit) {
                historyLead('/edit-user/role_details?id=' + user_detail.user_id);
            } else {
                historyLead('/add-user/role_details');
            }
        }
        else if (!user_detail.role_details) {
            props.handleReset()
            if (is_edit) {
                historyLead('/edit-user/role_details?id=' + user_detail.user_id);
            } else {
                historyLead('/add-user/role_details');
            }
        } else {
            if (user_detail.basic_details) {
                getBasicDetails(user_detail.basic_details)
                getBankList(user_detail.basic_details)
            }else{
                getBankList()
            }
            getReferralUserList()
        }
    }, [user_detail, props, historyLead, ConfigList]);

    const getBasicDetails = async (basic_details) => {
        setLoader(true)
        getCityAndLocalityData(basic_details, true)
    }

    const getCityAndLocalityData = async (basic_details, setData) => {
        let temp_basic_details = basic_details
        /* let business_line_index = ConfigList.business_line.findIndex((e) => e.value === basic_details.business_line)
        if (business_line_index === -1) {
            temp_basic_details["business_line"] = ""
        } */
        let employment_type_index = EmploymentType.findIndex((e) => e.value === basic_details.employment_type)
        if (employment_type_index === -1) {
            temp_basic_details["employment_type"] = ""
        }
        if (temp_basic_details.password) {
            setCurrentPassword(temp_basic_details.password)
            // setEnableNewPassword(true)
        }
        if (is_edit) {
            setShowPasswordValue(false)
            setShowConfirmPasswordValue(false)
            // temp_basic_details["password"] = ""
            // temp_basic_details["confirm_password"] = ""
        }
        await getCityList([basic_details.state_name], setData, temp_basic_details)

    }

    const getCityList = async (state_ids, setData, basic_details) => {
        let temp_basic_details = basic_details
        await MasterService.post('/sfa/user/city_list', { state_ids })
            .then(async function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    let city_index = city_list.findIndex(e => e.id === basic_details.city)
                    if (city_index === -1) {
                        temp_basic_details["city"] = ""
                    }

                    setCityList(city_list);
                    if (setData === true) {
                        MasterService.getLocalityList(basic_details.city).then((res) => {
                            let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                            setLocalityList(locality_list)
                            let locality_index = locality_list.findIndex(e => e.id === basic_details.user_location)
                            if (locality_index === -1) {
                                temp_basic_details["user_location"] = ""
                            }

                            setBasicDetail(temp_basic_details)
                            setLoader(false)
                        })
                    } else {
                        setBasicDetail(temp_basic_details)
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const getBankList = async (temp_basic_details={}) => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var bank_list = response.data.data.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId, valid_length:elm.valid_length }));
                    if(Object.keys(temp_basic_details).length && temp_basic_details.bank_id){
                        var bank_list_selected = bank_list.filter(elm => elm.id === temp_basic_details.bank_id);
                        let account_length = bank_list_selected && bank_list_selected.length ?bank_list_selected[0].valid_length:0
                        setAccountLength(account_length)
                    }
                    setBankList(bank_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve, reject) => {
            if (inputValue.trim().length > 2) {
                resolve(filterData(inputValue));
            }
        });
    };


    const filterData = (inputValue) => {
        return ReferralName.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const handleInputChange = async (target_name, type, event) => {        
        let temp_basic_details = { ...basic_details };
        let tempErrors = { ...errors }
        if (target_name === "state_name") {
            if (temp_basic_details.state_name !== event.value) {
                temp_basic_details[target_name] = event.value;
                tempErrors[target_name] = ""
                temp_basic_details["city"] = ""
                tempErrors["city"] = ""
                temp_basic_details["user_location"] = ""
                tempErrors["user_location"] = ""
                await getCityList([event.value], false, temp_basic_details)
                await MasterService.getLocalityList(event.value).then((res) => {
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                })
            }
        }

        else if (target_name === "city") {
            if (temp_basic_details.city !== event.value) {
                tempErrors[target_name] = ""
                temp_basic_details["user_location"] = ""
                tempErrors["user_location"] = ""
                temp_basic_details[target_name] = event.value;
                MasterService.getLocalityList(event.value).then((res) => {
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                })
            }
        }
        else if (target_name === "referral_name") {
            temp_basic_details[target_name] = event.id;
        }
        else if (type === "checkbox" || type === "select") {
            if(target_name === "bank_id"){
                setAccountLength(event.valid_length)
                temp_basic_details["bank_account_number"] = "";
                temp_basic_details["confirm_bank_account_number"] = "";
                temp_basic_details["bank_branch_name"] = "";
                temp_basic_details["beneficiary_name"] = "";
                // temp_basic_details["npwp"] = "";

                tempErrors["bank_account_number"] = ""
                tempErrors["confirm_bank_account_number"] = ""
                tempErrors["bank_branch_name"] = ""
                tempErrors["beneficiary_name"] = ""
                // tempErrors["npwp"] = ""

            }
            temp_basic_details[target_name] = event.value;
        } else if (type === "toggle") {
            temp_basic_details[target_name] = event;
        } else if (type === "number") {
            temp_basic_details[target_name] = event.formattedValue;
        } else if (target_name === "beneficiary_name") {
            temp_basic_details[target_name] = event.target.value;
        }else if (target_name === "is_subscribe_dif") {
            temp_basic_details[target_name] = event.target.checked ? 1 : 0;
        } else {
            temp_basic_details[target_name] = event.target.value;
        }

        if(target_name === "password" || target_name === "confirm_password"){
            setChangePassword(true)
            if(target_name === "password") setShowPasswordValue(true)
            if(target_name === "confirm_password") setShowConfirmPasswordValue(true)
        }

        if((target_name === "password" || target_name === "confirm_password") && event.target.value == ""){
            setChangePassword(false)
        }
        tempErrors[target_name] = ""

        
        if (target_name === "sss_id_number") {
            if (event.target.value.length <= 0) {
                tempErrors["sss_id_number"] = 'SSS ID Number is required';
            } else {
                delete tempErrors[target_name]
            }
        }
        

        SetErrors(tempErrors)
        setBasicDetail(temp_basic_details);
    }

    const handleDateChange = (value, name) => {
        let temp_basic_details = { ...basic_details };
        let tempError = {...errors};
        temp_basic_details[name] = value;
        tempError[name] = '';
        SetErrors(tempError);
        setBasicDetail(temp_basic_details);
    }

    const handleChangeImage = (event) => {
        let filesInfo = Array.from(event.target.files);
        if (filesInfo && filesInfo.length) {
            filesInfo.forEach((file, index) => {
                let browse_url = URL.createObjectURL(file);
                let temp_basic_details = { ...basic_details };
                temp_basic_details["profile_image"] = browse_url;
                temp_basic_details["profile_image_file"] = file;
                setBasicDetail(temp_basic_details);
            });
        }
    }

    const handleClickCancel = () => {
        if (user_detail?.user_id) {
            historyLead('/view-user/' + user_detail?.user_id_hash,  { replace: true })
        }
    }

    const handleClick = async (is_skip) => {
        let res = await isValidate()
        setLoader(false)
        if (res.formIsValid) {
            let temp_basic_details = { ...basic_details }
            temp_basic_details.current_password = current_password
            temp_basic_details.change_password = change_password
            // temp_basic_details.will_update_password = enable_new_password;
            if (temp_basic_details.password.trim() === "") {
                temp_basic_details.password = current_password
            }
            
            await props.handleSave(temp_basic_details, 'basic_details', is_skip)
            if (!is_skip) {
                if (is_edit) {
                    historyLead('/edit-user/documents?id=' + user_detail.user_id_hash);
                } else {
                    historyLead('/add-user/documents');
                }
            }
        }
    }

    const isValidate = async () => {
        setLoader(true)
        let tempBasicDetail = { ...basic_details };
        let formIsValid = true;
        let tempErrors = {};
        let tempExistingErrors = { ...existing_errors };
        let res = await checkExisting()
        if (!tempBasicDetail["username"]) {
            formIsValid = false;
            tempErrors["username"] = 'Username is required';
        }
        if (!tempBasicDetail["mobile_no"]) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile is required';
        } else if (tempBasicDetail["mobile_no"].length !== 10) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile length must be 10';
        }
        if(tempBasicDetail["mobile_no"] && (tempBasicDetail["mobile_no"].split(''))[0] !== '9'){
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile number must start with 9';
        }
       

        if (!tempBasicDetail["email_id"]) {
            formIsValid = false;
            tempErrors["email_id"] = 'Email is required';
        }
        else if (tempBasicDetail["email_id"].match(EMAIL_VALIDATION) == null) {
            tempErrors["email_id"] = 'Email is not valid';
        }
        if (tempBasicDetail["npwp"] && tempBasicDetail["npwp"].replace(/[^0-9]/g, "").length < 15) {
            tempErrors["npwp"] = 'NPWP is not valid';
        }
        if (!tempBasicDetail["sss_id_number"]) {
            formIsValid = false;
            tempErrors["sss_id_number"] = 'SSS ID Number is required';
        } else if (isNaN(tempBasicDetail["sss_id_number"])) {
            /*blank code*/
        } else if (tempBasicDetail["sss_id_number"].length !== 16) {
            /*blank code*/
        } else {
            delete tempErrors["sss_id_number"]
            delete tempExistingErrors["sss_id_number"]
        }
        if (!tempBasicDetail["joining_date"]) {
            formIsValid = false;
            tempErrors["joining_date"] = 'Joining Date is required';
        }
        if (!tempBasicDetail["bank_id"]) {
            formIsValid = false;
            tempErrors["bank_id"] = 'Bank Name is required';
        }
        if (!tempBasicDetail["bank_branch_name"]) {
            formIsValid = false;
            tempErrors["bank_branch_name"] = 'Branch is required';
        }

        if (!tempBasicDetail["bank_account_number"]) {
            formIsValid = false;
            tempErrors["bank_account_number"] = 'Account Number is required';
        }else if(tempBasicDetail["bank_account_number"].length !== account_length && account_length > 0){
            formIsValid = false;
            tempErrors["bank_account_number"] = 'Format of Account Number must '+account_length+' digits';
        }
        if (!tempBasicDetail["confirm_bank_account_number"]) {
            formIsValid = false;
            tempErrors["confirm_bank_account_number"] = 'Confirm Account Number is required';
        }else if(tempBasicDetail["confirm_bank_account_number"].length !== account_length && account_length > 0){
            formIsValid = false;
            tempErrors["confirm_bank_account_number"] = 'Format of Account Number must '+account_length+' digits';
        }else if (!(tempBasicDetail["bank_account_number"].trim() === "" && tempBasicDetail["confirm_bank_account_number"].trim() === "") && tempBasicDetail["confirm_bank_account_number"] !== tempBasicDetail["bank_account_number"]) {
            formIsValid = false;
            tempErrors["confirm_bank_account_number"] = 'Account Number not matching';
        }
        if (!tempBasicDetail["beneficiary_name"]) {
            formIsValid = false;
            tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
        }
        
        if (!tempBasicDetail["state_name"]) {
            formIsValid = false;
            tempErrors["state_name"] = 'State is required';
        }
        if (!tempBasicDetail["city"]) {
            formIsValid = false;
            tempErrors["city"] = 'City is required';
        }
        if (!tempBasicDetail["postal_code"]) {
            formIsValid = false;
            tempErrors["postal_code"] = 'Postal Code is required';
        }
        /* if (!tempBasicDetail["business_line"]) {
            formIsValid = false;
            tempErrors["business_line"] = 'Business Line is required';
        } */
        /*if (!is_edit && !tempBasicDetail["password"]) {
            formIsValid = false;
            tempErrors["password"] = 'Password is required';

        }
        if (!is_edit && !tempBasicDetail["confirm_password"]) {
            formIsValid = false;
            tempErrors["confirm_password"] = 'Confirm Password is required';
        }
        if(is_edit && tempBasicDetail["password"] == ""){
            tempBasicDetail["confirm_password"] = "";
        }
        if (!(tempBasicDetail["password"].trim() === "" && tempBasicDetail["confirm_password"].trim() === "")) {
            if (tempBasicDetail["confirm_password"] === tempBasicDetail["password"]) {
                if (show_password_value && show_confirm_password_value) {
                    let result = validatePassword(tempBasicDetail["password"]);
                    if (result !== "") {
                        tempErrors["password"] = result
                        formIsValid = false;
                    }
                }
            }else{
                formIsValid = false;
                tempErrors["confirm_password"] = 'Password does not match ';
            }
        }*/

        if ((!tempBasicDetail["current_occupation"] || tempBasicDetail["current_occupation"] == 0)&& validationForFields.current_occupation.includes(user_detail.role_details?.role)) {
            formIsValid = false;
            tempErrors["current_occupation"] = 'Current Occupation is required';
        }
        if (!tempBasicDetail["company_name"] && validationForFields.company_name.includes(user_detail.role_details?.role)) {
            formIsValid = false;
            tempErrors["company_name"] = 'Company Name is required';
        }
        if ((!tempBasicDetail["designation"] || tempBasicDetail["designation"] == 0 || BasicDetailConfig.designation.filter((e) => e.value === tempBasicDetail.designation).length == 0)&& validationForFields.designation.includes(user_detail.role_details.role)) {
            formIsValid = false;
            tempErrors["designation"] = 'Designation is required';
        }
        if (!tempBasicDetail["tagging_id"] && validationForFields.tagging.includes(user_detail.role_details?.role)) {
            formIsValid = false;
            tempErrors["tagging_id"] = 'Tagging is required';
        }
        if ((!tempBasicDetail["source"] || tempBasicDetail["source"] == 0 || BasicDetailConfig.source.filter((e) => e.value === basic_details.source).length == 0) && validationForFields.source.includes(user_detail.role_details.role)) {
            formIsValid = false;
            tempErrors["source"] = 'Source is required';
        }

        if (tempBasicDetail["employment_type"] == "" || !tempBasicDetail["employment_type"]) {
            formIsValid = false;
            tempErrors["employment_type"] = 'Employment Type is required';
        }

        if (validationForFields.tin_id.includes(user_detail.role_details?.role)) {
            /*blank code*/
        }
        
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }

        if (formIsValid) {
            formIsValid = res
        } else {
            SetErrors(tempErrors);
        }
        let return_res = {
            formIsValid,
            tempErrors
        }
        return return_res;
    }

    const validatePassword = (password) => {
        let error_message = ""
        if (change_password && current_password === md5(password).trim()) {
            error_message = "Can not set old password"
        } else if (password && ('' + password).match(PASSWORD_VALIDATION) == null) {
            error_message = "Should be of at least 8 characters. Must include 1 lowercase,1 uppercase,1 digit and 1 special character";
        }
        return error_message
    }

    const checkExisting = async (event) => {
        let formIsValid = true;
        let tempErrors = { ...errors };
        let tempExistingErrors = { ...existing_errors };
        let data_exist = false
        let call_api = true
        let params = {}

        if (basic_details.mobile_no) {
            params.mobile_no = basic_details.mobile_no ? basic_details.mobile_no : ""
        }

        if (basic_details.bank_account_number && basic_details.confirm_bank_account_number) {
            if(basic_details.bank_account_number.trim() !== "" && basic_details.bank_account_number.trim() === basic_details.confirm_bank_account_number.trim()){
                params.bank_account_number = basic_details.bank_account_number ? basic_details.bank_account_number : 0
                params.bank_id = basic_details.bank_id ? basic_details.bank_id : 0
            }else{
                call_api = false
            }
        }
        if (basic_details.npwp) {
            params.npwp = basic_details.npwp ? basic_details.npwp : ""
        }
        if (basic_details.sss_id_number) {
            // params.sss_id_number = basic_details.sss_id_number && !isNaN(basic_details.sss_id_number) ? basic_details.sss_id_number : 0
            params.sss_id_number = basic_details.sss_id_number || 0
        }
        if (basic_details.email_id) {
            params.email_id = basic_details.email_id ? basic_details.email_id : ""
        }


        if (event && event.target.name === "sss_id_number") {
            if (basic_details["sss_id_number"] && basic_details["sss_id_number"].length <= 0) {
                tempErrors["sss_id_number"] = 'SSS ID Number is required';
            } else {
                delete tempErrors["sss_id_number"]
                delete tempExistingErrors["sss_id_number"]
            }
        }

        if (Object.keys(params).length && call_api) {
            if (user_detail.user_id) {
                params.user_id = user_detail.user_id
    
            }
            await MasterService.post('/sfa/user/check_existing', params, {}).then(function (response) {
                if (response.data.status === 200) {
                    let response_data = response.data.data
                    if (response_data.mobile) {
                        if (response_data.mobile.is_exist === true) {
                            tempErrors["mobile_no"] = response_data.mobile.error_message
                            tempExistingErrors["mobile_no"] = response_data.mobile.error_message
                        } else {
                            delete tempErrors["mobile_no"]
                            delete tempExistingErrors["mobile_no"]
                        }

                    }
                    if (response_data.email) {
                        if (response_data.email.is_exist === true) {
                            tempErrors["email_id"] = response_data.email.error_message
                            tempExistingErrors["email_id"] = response_data.email.error_message
                        } else {
                            delete tempErrors["email_id"]
                            delete tempExistingErrors["email_id"]
                        }
                    }
                    if (response_data.sss_id_number) {
                        if (response_data.sss_id_number.is_exist === true) {
                            tempErrors["sss_id_number"] = response_data.sss_id_number.error_message
                            tempExistingErrors["sss_id_number"] = response_data.sss_id_number.error_message
                        } else {
                            delete tempErrors["sss_id_number"]
                            delete tempExistingErrors["sss_id_number"]
                        }
                    }
                    if (response_data.npwp) {
                        if (response_data.npwp.is_exist === true) {
                            tempErrors["npwp"] = response_data.npwp.error_message
                            tempExistingErrors["npwp"] = response_data.npwp.error_message
                        } else {
                            delete tempErrors["npwp"]
                            delete tempExistingErrors["npwp"]
                        }
                    }
                    if (response_data.bank_account_number) {
                        if (response_data.bank_account_number.is_exist === true) {
                            tempErrors["bank_account_number"] = response_data.bank_account_number.error_message
                            tempExistingErrors["bank_account_number"] = response_data.bank_account_number.error_message
                        } else {
                            delete tempErrors["bank_account_number"]
                            delete tempExistingErrors["bank_account_number"]
                        }
                    }
                }
            }).catch(function (response) {
            });

            if (Object.keys(tempExistingErrors).length) {
                data_exist = true
                formIsValid = false
            } else {
                data_exist = false
                formIsValid = true
            }
            setIsExisting(data_exist)
            SetErrors(tempErrors);
            SetExistingErrors(tempExistingErrors)
            return formIsValid
        } else {
            return formIsValid
        }

    }

    const changePasswordType = (type) => {
        if (type === "text") {
            setPasswordType("password")
        }
        else if (type === "password") {
            setPasswordType("text")
        }
    }

    const changeConfirmPasswordType = (type) => {
        if (type === "text") {
            setConfirmPasswordType("password")
        }
        else if (type === "password") {
            setConfirmPasswordType("text")
        }
    }

    return (
            <div className='basic-detail-form p-xxl add-user-detail-filed'>
                {loader ? <Loader /> : null}
                {user_detail.status === "1" &&
                    <button className='btn-line m-xs-l save_and_skip' onClick={handleClick.bind(this, true)}>
                        Save And Skip
                    </button>
                }
                <h2>Basic Details</h2>
                <div className='add-photo-btn'>
                    {basic_details.profile_image && basic_details.profile_image !== "" ?
                        <div className="add-profile-photo">
                            <span className="add-img-txt">Add Profile Photo</span>
                            <span className="subheading">Only jpg and png files are accepted</span>
                            <div className="add-photo-btn">
                                <div className="preview-img">
                                    <img src={basic_details.profile_image} alt=''/>
                                </div>
                                <div className="fileUpload">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        id="re-upload-image-input"
                                        className="upload"
                                        onChange={handleChangeImage}
                                    />
                                    <span>Reupload Photo</span>
                                </div>
                            </div>
                        </div> :
                        <div className="add-profile-photo">
                            <span className="add-img-txt">Add Profile Photo</span>
                            <span className="subheading">Only jpg and png files are accepted</span>
                            <div className="add-photo-btn">
                                <div className="fileUpload">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        id="upload-image-input"
                                        className="upload"
                                        onChange={handleChangeImage}
                                    />
                                    <span>Upload</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <h3>Personal Details</h3>

                <div className="row">
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.username) ? errors.username : ''} name="username" id="username" label="Username" placeholder=" " value={basic_details.username} onChange={handleInputChange.bind(null, 'username', 'text')} required={true} />
                            <label data-label="Username*" className="form-label"></label>

                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField disabled={basic_details.is_mobile_no_verified === "1" ? true : false} onBlur={checkExisting} type="number" error={(errors.mobile_no) ? errors.mobile_no : ''} maxLength={12} name="mobile_no" id="mobile_no" label="Mobile" placeholder=" " value={basic_details.mobile_no} onChange={handleInputChange.bind(null, 'mobile_no', 'text')} required={true} />
                            <label data-label="Mobile*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField onBlur={checkExisting} type="email" error={(errors.email_id) ? errors.email_id : ''} name="email_id" id="email_id" label="Email" placeholder=" " value={basic_details.email_id} onChange={handleInputChange.bind(null, 'email_id', 'text')} required={true} />
                            <label data-label="Email*" className="form-label"></label>
                        </div>
                    </fieldset>

                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField onBlur={checkExisting} type="text" error={(errors.sss_id_number) ? errors.sss_id_number : ''} name="sss_id_number" id="sss_id_number" label="SSS ID Number" placeholder=" " value={basic_details.sss_id_number} onChange={handleInputChange.bind(null, 'sss_id_number', 'text')} required={true} />
                            <label data-label="SSS ID Number*" className="form-label"></label>

                        </div>
                    </fieldset>   
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.tin_id) ? errors.tin_id : ''} name="tin_id" id="tin_id" label="TIN ID (Secondary ID)" placeholder=" " value={basic_details.tin_id} onChange={handleInputChange.bind(null, 'tin_id', 'text')} required={true} />
                            <label data-label="TIN ID (Secondary ID)" className="form-label"></label>

                        </div>
                    </fieldset>                                     
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={EmploymentType}
                                value={EmploymentType.filter((e) => e.value === basic_details.employment_type)}
                                onChange={handleInputChange.bind(null, 'employment_type', "checkbox")}
                                placeholder="Employment Type*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                        (errors && errors.employment_type) ? <span className="error show">{errors.employment_type}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.employee_id) ? errors.employee_id : ''} name="employee_id" id="employee_id" label="Employee ID" placeholder=" " value={basic_details.employee_id} onChange={handleInputChange.bind(null, 'employee_id', 'text')} />
                            <label data-label="Employee ID" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <JoiningDate
                                value={basic_details.joining_date}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.joining_date) ? <label data-label="Joining Date*" className="form-label date-label"></label> : ''}
                        </div>
                        {
                            (errors && errors.joining_date) ? <span className="error show">{errors.joining_date}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Anniversary
                                value={basic_details.anniversary_date}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.anniversary_date) ? <label data-label="Anniversary" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                </div>
                <h3>Bank Details</h3>
                <div className="row">
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={bankList}
                                value={bankList.filter((e) => e.value === basic_details.bank_id)}
                                onChange={handleInputChange.bind(null, 'bank_id', "select")}
                                placeholder="Bank Name*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.bank_id) ? <span className="error show">{errors.bank_id}</span> : ''
                        }
                    </fieldset>

                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.bank_branch_name) ? errors.bank_branch_name : ''} name="bank_branch_name" id="bank_branch_name" label="Branch" placeholder=" " value={basic_details.bank_branch_name} onChange={handleInputChange.bind(null, 'bank_branch_name', 'text')} required={true} />
                            <label data-label="Branch*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField onBlur={checkExisting} type="password" error={(errors.bank_account_number) ? errors.bank_account_number : ''} name="bank_account_number" id="bank_account_number" label="Account Number" placeholder=" " value={basic_details.bank_account_number} onChange={handleInputChange.bind(null, 'bank_account_number', 'text')} required={true} maxLength={account_length} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            <label data-label="Account Number*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField onBlur={checkExisting} type="number" error={(errors.confirm_bank_account_number) ? errors.confirm_bank_account_number : ''} name="confirm_bank_account_number" id="confirm_bank_account_number" label="Confirm Account Number" placeholder=" " value={basic_details.confirm_bank_account_number} onChange={handleInputChange.bind(null, 'confirm_bank_account_number', 'text')} required={true} maxLength={account_length} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            <label data-label="Confirm Account Number*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" label="Beneficiary Name" placeholder=" " value={basic_details.beneficiary_name} onChange={handleInputChange.bind(null, 'beneficiary_name', 'text')} required={true} />
                            <label data-label="Beneficiary Name*" className="form-label"></label>
                        </div>
                    </fieldset>
                    
                </div>

                <h3>Other Details</h3>
                <div className="row">
                    <fieldset className="col-md-4">
                        <div className="material">
                            <DOBDate
                                value={basic_details.date_of_birth}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.date_of_birth) ? <label data-label="DOB (YYYY-MM-DD)" className="form-label date-label"></label> : ''}
                        </div>
                        {
                            (errors && errors.date_of_birth) ? <span className="error show">{errors.date_of_birth}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={stateList}
                                value={stateList.filter((e) => e.value === basic_details.state_name)}
                                onChange={handleInputChange.bind(null, 'state_name', "select")}
                                placeholder="State*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.state_name) ? <span className="error show">{errors.state_name}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={cityList}
                                value={cityList.filter((e) => e.value === basic_details.city)}
                                onChange={handleInputChange.bind(null, 'city', "select")}
                                placeholder="City*"
                                className="react-select"
                                isDisabled={basic_details.state_name && basic_details.state_name !== "" ? false : true}
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.city) ? <span className="error show">{errors.city}</span> : ''
                        }
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select 
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                options={localityList}
                                value={localityList.findIndex(e => e.value === basic_details.user_location) > -1 ? localityList.filter((e) => e.value === basic_details.user_location) : ""}
                                onChange={handleInputChange.bind(null, 'user_location', "select")}
                                placeholder="Locality"
                                className="react-select"
                                isDisabled={basic_details.city && basic_details.city !== "" ? false : true}
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    {validationForFields.current_occupation.includes(user_detail.role_details?.role) &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select 
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator:() => null }}
                                    options={BasicDetailConfig.current_occupation}
                                    value={BasicDetailConfig.current_occupation.filter((e) => e.value === basic_details.current_occupation)}
                                    onChange={handleInputChange.bind(null, 'current_occupation', "select")}
                                    placeholder="Current Occupation*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.current_occupation) ? <span className="error show">{errors.current_occupation}</span> : ''
                            }
                        </fieldset>
                    }
                    {validationForFields.company_name.includes(user_detail.role_details?.role) &&
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="text" error={(errors.company_name) ? errors.company_name : ''} name="company_name" id="company_name" label="Company" placeholder=" " value={basic_details.company_name} onChange={handleInputChange.bind(null, 'company_name', 'text')} required={true} />
                                <label data-label="Company*" className="form-label"></label>
                            </div>
                        </fieldset>
                    }
                    {validationForFields.designation.includes(user_detail.role_details?.role) &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select 
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator:() => null }}
                                    options={BasicDetailConfig.designation}
                                    value={BasicDetailConfig.designation.filter((e) => e.value === basic_details.designation)}
                                    onChange={handleInputChange.bind(null, 'designation', "select")}
                                    placeholder="Designation*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.designation) ? <span className="error show">{errors.designation}</span> : ''
                            }
                        </fieldset>
                    }
                    {validationForFields.tagging.includes(user_detail.role_details?.role) && ConfigList && ConfigList.tagging_roles && ConfigList.tagging_roles.length > 0 &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select 
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator:() => null }}
                                    options={ConfigList.tagging_roles}
                                    value={ConfigList.tagging_roles.filter((e) => e.value === basic_details.tagging_id)}
                                    onChange={handleInputChange.bind(null, 'tagging_id', "select")}
                                    placeholder="Tagging*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.tagging_id) ? <span className="error show">{errors.tagging_id}</span> : ''
                            }
                        </fieldset>
                    }
                    {/* {ConfigList && ConfigList.business_line && ConfigList.business_line.length > 0 &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select 
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator:() => null }}
                                    options={ConfigList.business_line}
                                    value={ConfigList.business_line.filter((e) => e.value === basic_details.business_line)}
                                    onChange={handleInputChange.bind(null, 'business_line', "select")}
                                    placeholder="Business Line*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.business_line) ? <span className="error show">{errors.business_line}</span> : ''
                            }
                        </fieldset>
                    } */}
                    {validationForFields.source.includes(user_detail.role_details?.role) &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select 
                                    components={{ ValueContainer: CustomValueContainer }}
                                    // components={{ IndicatorSeparator:() => null }}
                                    options={BasicDetailConfig.source}
                                    value={BasicDetailConfig.source.filter((e) => e.value === basic_details.source)}
                                    onChange={handleInputChange.bind(null, 'source', "select")}
                                    placeholder="Source*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.source) ? <span className="error show">{errors.source}</span> : ''
                            }
                        </fieldset>
                    }
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="number" error={(errors.postal_code) ? errors.postal_code : ''} name="postal_code" id="postal_code" label="Postal Code" placeholder=" " value={basic_details.postal_code} onChange={handleInputChange.bind(null, 'postal_code', 'text')} required={true} />
                            <label data-label="Postal Code*" className="form-label"></label>

                        </div>
                    </fieldset>              
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.aro_so_assigned) ? errors.aro_so_assigned : ''} name="aro_so_assigned" id="aro_so_assigned" label="ARO/SO assigned" placeholder=" " value={basic_details.aro_so_assigned} onChange={handleInputChange.bind(null, 'aro_so_assigned', 'text')} required={true} />
                            <label data-label="ARO/SO assigned" className="form-label"></label>
                        </div>
                    </fieldset>


                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.house_number) ? errors.house_number : ''} name="house_number" id="house_number" label="House/Plot" placeholder=" " value={basic_details.house_number} onChange={handleInputChange.bind(null, 'house_number', 'text')} required={true} />
                            <label data-label="House/Plot" className="form-label"></label>
                        </div>
                    </fieldset>
                    {validationForFields.incentive_percentage.includes(user_detail.role_details?.role) &&
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="number" min="0" max="100" error={(errors.incentive_percentage) ? errors.incentive_percentage : ''} name="address" id="incentive_percentage" label="Incentive%" placeholder=" " value={basic_details.incentive_percentage} onChange={handleInputChange.bind(null, 'incentive_percentage', 'text')} required={true} />
                                <label data-label="Incentive%" className="form-label"></label>
                            </div>
                        </fieldset>
                    }
                    <fieldset className="col-md-8">
                        <div className="material">
                            <InputField type="text" error={(errors.address) ? errors.address : ''} name="address" id="address" label="Street Address" placeholder=" " value={basic_details.address} onChange={handleInputChange.bind(null, 'address', 'text')} required={true} />
                            <label data-label="Street Address" className="form-label"></label>
                        </div>
                    </fieldset>
                    {validationForFields.is_tax_booster_enable.includes(user_detail.role_details?.role) &&
                        <fieldset className="col-md-4">
                            <div className="material toggle_outer">
                                <span className='m-md-r booster-text'>Tax Booster</span>
                                <ToggleSwitch toggleChecked={basic_details.is_tax_booster_enable ? basic_details.is_tax_booster_enable : false} onClick={handleInputChange.bind(null, 'is_tax_booster_enable', 'toggle')} />
                                {/* <label data-label="Tax Booster Enable" className="form-label"></label> */}
                            </div>
                        </fieldset>
                    }

                </div>

                {is_edit && <h3>Additional Details</h3>}
                {is_edit &&
                    <div className="row">


                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.created_date}
                                    text={"Created Date"}
                                    name={"created_date"}
                                    disabled={true}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.created_date) ? <label data-label="Created Date" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.approval_date}
                                    text={"Approval Date"}
                                    name={"approval_date"}
                                    disabled={true}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.approval_date) ? <label data-label="Approval Date" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="text" error={(errors.onboarded_from) ? errors.onboarded_from : ''} name="onboarded_from" id="onboarded_from" label="Onboarded From" placeholder=" " value={basic_details.onboarded_from} onChange={handleInputChange.bind(null, 'onboarded_from', 'text')} required={true} disabled={true} />
                                <label data-label="Onboarded From" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField type="text" error={(errors.onboarded_by) ? errors.onboarded_by : ''} name="house_number" id="onboarded_by" label="Onboarded By" placeholder=" " value={basic_details.onboarded_by} onChange={handleInputChange.bind(null, 'onboarded_by', 'text')} required={true} disabled={true} />
                                <label data-label="Onboarded By" className="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.resign_date_1}
                                    text={"Resign Date 1"}
                                    name={"resign_date_1"}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.resign_date_1) ? <label data-label="Resign Date 1" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.resign_date_2}
                                    text={"Resign Date 2"}
                                    name={"resign_date_2"}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.resign_date_2) ? <label data-label="Resign Date 2" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.rejoin_date_1}
                                    text={"Rejoin Date 1"}
                                    name={"rejoin_date_1"}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.rejoin_date_1) ? <label data-label="Rejoin Date 1" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.rejoin_date_2}
                                    text={"Rejoin Date 2"}
                                    name={"rejoin_date_2"}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.rejoin_date_2) ? <label data-label="Rejoin Date 2" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset> 
                        
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.warning_date_1}
                                    text={"Warning Date 1"}
                                    name={"warning_date_1"}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.warning_date_1) ? <label data-label="Warning Date 1" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset>
                        <fieldset className="col-md-4">
                            <div className="material">
                                <Calender3
                                    value={basic_details.warning_date_2}
                                    text={"Warning Date 2"}
                                    name={"warning_date_2"}
                                    handleDateChange={handleDateChange}
                                />
                                {(basic_details.warning_date_2) ? <label data-label="Warning Date 2" className="form-label date-label"></label> : ''}
                            </div>
                        </fieldset> 

                        <fieldset className="col-md-8">
                            <div className="material">
                                <InputField type="text" error={(errors.comments) ? errors.comments : ''} name="comments" id="comments" label="Comments" placeholder=" " value={basic_details.comments} onChange={handleInputChange.bind(null, 'comments', 'text')} required={true} />
                                <label data-label="Comments" className="form-label"></label>
                            </div>
                        </fieldset>

                    </div>
                }
                {/*<div>
                    <h3 style={{display:"flex"}}>Set Password
                    {is_edit && <span onClick={() => setEnableNewPassword(!enable_new_password)} className="enable_password">{enable_new_password?"Enable New Password":"Disable New Password"}</span>}
                    </h3>
                </div>

                <div className="row">

                    <fieldset className="col-md-4">
                        <div className="material info-icn">
                            {password_type === "text" &&
                                <InputField disabled={enable_new_password} type="text" error={(errors.password) ? errors.password : ''} name="password" id="password" label="Password" placeholder=" " value={show_password_value?basic_details.password:""} onChange={handleInputChange.bind(null, 'password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            {password_type === "password" &&
                                <InputField disabled={enable_new_password} type="password" error={(errors.password) ? errors.password : ''} name="password" id="password" label="Password" placeholder=" " value={show_password_value?basic_details.password:""} onChange={handleInputChange.bind(null, 'password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            <label data-label="Password*" className="form-label"></label>
                            <span className="ic-showhide-password" onClick={() => changePasswordType(password_type)}><i className="ic-remove_red_eyevisibility" ></i></span>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material info-icn">
                            {confirm_password_type === "text" &&
                                <InputField disabled={enable_new_password} type="text" error={(errors.confirm_password) ? errors.confirm_password : ''} name="confirm_password" id="confirm_password" label="Confirm Password" placeholder=" " value={show_confirm_password_value?basic_details.confirm_password:""} onChange={handleInputChange.bind(null, 'confirm_password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            {confirm_password_type === "password" &&
                                <InputField disabled={enable_new_password} type="password" error={(errors.confirm_password) ? errors.confirm_password : ''} name="confirm_password" id="confirm_password" label="Confirm Password" placeholder=" " value={show_confirm_password_value?basic_details.confirm_password:""} onChange={handleInputChange.bind(null, 'confirm_password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            <label data-label="Confirm Password*" className="form-label"></label>
                            <span className="ic-showhide-password" onClick={() => changeConfirmPasswordType(confirm_password_type)}><i className="ic-remove_red_eyevisibility" ></i></span>

                        </div>
                    </fieldset>
                </div>
                        */}
                <div>
                    <h3>Dealer Inventory Funding</h3>
                    <div className="row">

                        <fieldset className="col-md-6">
                            <li className="custom-control custom-checkbox">
                                <span>Dealer Inventory Funding Subscribe:</span>
                                <input
                                    id={`is_subscribe_dif`}
                                    type="checkbox"
                                    name="is_subscribe_dif"
                                    onChange={handleInputChange.bind(null, 'is_subscribe_dif', 'check')}
                                    className="custom-control-input"
                                    checked={basic_details.is_subscribe_dif ? true : false}
                                />

                                <label htmlFor={`is_subscribe_dif`} className="custom-control-label">
                                    {/* Sales Wallet */}
                                </label>
                            </li>
                        </fieldset>
                    </div>
                </div>
                {validationForFields.referral_name.includes(user_detail.role_details?.role) &&
                    <div>
                        <h3>Reference</h3>
                        <div className="row">

                            <fieldset className="single-select col-md-4">
                                <div className="material">
                                    <AsyncSelect
                                        components={{ ValueContainer: CustomValueContainer }}
                                        onChange={handleInputChange.bind(null, 'referral_name', "select")}
                                        value={ReferralName.filter((e) => e.value === basic_details.referral_name)}
                                        loadOptions={e => promiseOptions(e)}
                                        // placeholder={"Start Typing..."}
                                        placeholder={"Referral Name & ID"}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                    />

                                </div>
                                <span className="message show">Type Atleast 3 Character</span>
                                <br />
                                {
                                    (errors && errors.referral_name) ? <span className="error show">{errors.referral_name}</span> : ''
                                }
                            </fieldset>
                        </div>
                    </div>
                }
                <div className='br-top-flex'>
                    <div className="t-right">
                        {user_detail.status === "1" &&
                            <button className='btn-line m-xs-l' onClick={handleClick.bind(this, true)}>
                                Save And Skip
                            </button>
                        }
                    </div>
                    {is_edit ? <div className="t-right">
                        <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                            Cancel
                        </button>
                    </div> : null}
                    <div className="t-right">
                        <button className='btn-primary' onClick={handleClick.bind(this, false)}>
                            Next
                        </button>
                    </div>
                </div>

            </div>

    )
    // }
}
export default BasicDetails;