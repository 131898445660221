import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Loader from "../common/loader";
import InputField from "../element/Input";
import { EMAIL_VALIDATION_ADMIN } from "../../config/constant";
import { MasterService } from "../../services";
import { toast } from "react-toastify";

const { ValueContainer, Placeholder } = components;

const AddAdminBasicDetails = (props) => {
    let { adminDetails } = props;
    const [loading, setLoading] = useState(false);
    const [adminBasicDetails, setAdminBasicDetails] = useState(adminDetails && Object.keys(adminDetails) ? adminDetails : {});
    const [isEdit, setIsEdit] = useState(false)
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (adminDetails && Object.keys(adminDetails)) {
            setAdminBasicDetails(adminDetails);
            setIsEdit(true);
        }
    }, [adminDetails])

    // Event handling
   /*  const handleChangeImage = (event) => {
        let filesInfo = Array.from(event.target.files);
        if (filesInfo && filesInfo.length) {
            filesInfo.forEach((file, index) => {
                let browse_url = URL.createObjectURL(file);
                let tempAdminBasicDetails = { ...adminBasicDetails };
                tempAdminBasicDetails["profile_image_path"] = browse_url;
                tempAdminBasicDetails["profile_image_file"] = file;
                setAdminBasicDetails(tempAdminBasicDetails);
            });
        }
    }; */

    const handleInputChange = (event, name, type) => {
        let tempAdminBasicDetails = { ...adminBasicDetails };
        let tempErrors = { ...errors };
        if (name === "name") {
            tempErrors["name"] = "";
            tempAdminBasicDetails["name"] = event.target.value;
        } else if (name === "mobile") {
            tempErrors["mobile"] = "";
            tempAdminBasicDetails["mobile"] = event.target.value;
        } else if (name === "email") {
            tempErrors["email"] = "";
            tempAdminBasicDetails["email"] = event.target.value;
        } else if (name === "admin_role") {
            tempErrors["admin_role"] = "";
            tempAdminBasicDetails["admin_role"] = event.id;
        }
        setErrors(tempErrors);
        setAdminBasicDetails(tempAdminBasicDetails);
    };

    const handleSave = async () => {
        let validForm = await isFormValid();
        if (validForm) {
            await props.handleCreateUser(adminBasicDetails);
        }
    };

    // Helper functions
    const isFormValid = async () => {
        setLoading(true);
        let checkData = {};
        let tempAdminBasicDetails = { ...adminBasicDetails };
        let validForm = true;
        let tempErrors = { ...errors };

        if (!tempAdminBasicDetails["name"]) {
            validForm = false;
            tempErrors["name"] = "Name is required";
        }

        if (!tempAdminBasicDetails["mobile"]) {
            validForm = false;
            tempErrors["mobile"] = "Mobile is required";
        } else if (tempAdminBasicDetails["mobile"].length < 8) {
            validForm = false;
            tempErrors["mobile"] = "Mobile length must be between 8 and 12";
        } else if (tempAdminBasicDetails["mobile"].length > 13) {
            validForm = false;
            tempErrors["mobile"] = "Mobile length must be between 8 and 12";
        } else if (!tempAdminBasicDetails["mobile"].startsWith("8") && !tempAdminBasicDetails["mobile"].startsWith("9")) {
            validForm = false;
            tempErrors["mobile"] = "Mobile must start with 8 or 9";
        } else {
            checkData.mobile = true;
        }

        if (!tempAdminBasicDetails["email"]) {
            validForm = false;
            tempErrors["email"] = "Email is required";
        } else if (tempAdminBasicDetails["email"].match(EMAIL_VALIDATION_ADMIN) == null) {
            validForm = false;
            tempErrors["email"] = "Email is not valid";
        } else {
            checkData.email = true;
        }

        if (!tempAdminBasicDetails["admin_role"]) {
            validForm = false;
            tempErrors["admin_role"] = "Role is required";
        }

        if (!adminDetails && Object.keys(checkData).length > 0 && Object.values(checkData).every((item) => item === true)) {
            try {
                const response = await MasterService.postCommon("/admin/checkAdminUser", tempAdminBasicDetails);
                if (response && response.data && response.data.status === 200) {
                    let responseData = response.data.data;
                    for (const data of responseData) {
                        if (data.conflict === true) {
                            validForm = false;
                            tempErrors[data.conflictType] = `${data.conflictType} already exists with admin ID (${data.conflictingUser.id})`;
                        }
                    }
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error(error);
            }
        }
        setErrors(tempErrors);
        setLoading(false);
        return validForm;
    };

    return (
        <React.Fragment>
            <div className="basic-detail-form p-xxl add-user-detail-filed">
                {loading ? <Loader /> : null}
                <h2>Basic Details</h2>
                <div className="agent_wallet">
                    {/*  <div className="add-photo-btn">
                        {adminBasicDetails.profile_image_path && adminBasicDetails.profile_image_path !== "" ? (
                            <div className="add-profile-photo">
                                <span className="add-img-txt">Add Profile Photo</span>
                                <span className="subheading">Only jpg and png files are accepted</span>
                                <div className="add-photo-btn">
                                    <div className="preview-img">
                                        <img src={adminBasicDetails.profile_image_path} alt="User Profile" />
                                    </div>
                                    {
                                        !isEdit &&
                                        <div className="fileUpload">
                                            <input
                                                type="file"
                                                name="image"
                                                accept="image/*"
                                                id="re-upload-image-input"
                                                className="upload"
                                                onChange={handleChangeImage}
                                            />
                                            <span>Reupload Photo</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="add-profile-photo">
                                <span className="add-img-txt">Add Profile Photo</span>
                                <span className="subheading">Only jpg and png files are accepted</span>
                                <div className="add-photo-btn">
                                    <div className="fileUpload">
                                        <input
                                            type="file"
                                            name="image"
                                            accept="image/*"
                                            id="upload-image-input"
                                            className="upload"
                                            onChange={handleChangeImage}
                                        />
                                        <span>Upload</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>
                <h3>Personal Details</h3>
                <div className="row">
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField
                                type="text"
                                error={errors.name ? errors.name : ""}
                                name="name"
                                id="name"
                                label="Name"
                                placeholder="Name"
                                value={adminBasicDetails.name}
                                onChange={(e) => {
                                    handleInputChange(e, "name", "text");
                                }}
                                required={true}
                            />
                            <label data-label="Name*" className="form-label"></label>
                        </div>
                    </fieldset>

                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField
                                type="number"
                                error={errors.mobile ? errors.mobile : ""}
                                name="mobile_no"
                                id="mobile"
                                label="Mobile"
                                placeholder="Mobile"
                                value={adminBasicDetails.mobile}
                                maxLength={12}
                                onChange={(e) => {
                                    handleInputChange(e, "mobile", "text");
                                }}
                                required={true}
                            />
                            <label data-label="Mobile*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField
                                type="text"
                                error={errors.email ? errors.email : ""}
                                name="email"
                                id="email"
                                label="Email"
                                placeholder="Email"
                                value={adminBasicDetails.email}
                                onChange={(e) => {
                                    handleInputChange(e, "email", "text");
                                }}
                                required={true}
                                disabled={isEdit}
                            />
                            <label data-label="Email*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                options={props.adminRole}
                                value={props.adminRole.filter((item) => adminBasicDetails.admin_role === item.id)}
                                onChange={(e) => {
                                    handleInputChange(e, "admin_role", "select");
                                }}
                                placeholder="Roles"
                                className="react-select"
                                classNamePrefix="react-select"
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ label }) => label}
                            />
                        </div>
                        {errors && errors.admin_role ? <span className="error show">{errors.admin_role}</span> : ""}
                    </fieldset>
                </div>

                <div className="br-top-flex">
                    <div className="t-right">
                        <button
                            type="button"
                            className="btn-primary"
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            Save & Continue
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

export default AddAdminBasicDetails;
